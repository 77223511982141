<template>
  <div class="home">
    <!-- header -->
    <Header></Header>

    <div class="container">
      <!-- 左侧导航栏 -->
      <side-bar></side-bar>

      <div class="content">
        <img class='mall-intro' :src='downloadSrc' />
        <img class='mall-intro' :src='residenceSrc' />
        <img class='mall-intro' :src='raceSrc' />
        <img class='mall-intro' :src='artSrc' />
        <img class='mall-intro' :src='storeSrc' />
        <img class='mall-intro' :src='mallSrc' />

        <!-- 底部 -->
        <Footer></Footer>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import SideBar from '@/components/SideBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'DigitalMall',
  data: function() {
    return {
      downloadSrc: require('../assets/images/download.jpg'),
      residenceSrc: require('../assets/images/residence.jpg'),
      raceSrc: require('../assets/images/racing.jpg'),
      artSrc: require('../assets/images/art.jpg'),
      storeSrc: require('../assets/images/store.jpg'),
      mallSrc: require('../assets/images/intro.png'),
      videoUrl: require('../assets/video/dv101.mp4'),
      isShowTip: false,
      message: '敬请期待'
    }
  },
  components: {
    Header,
    Footer,
    SideBar
  },
  mounted() {
  },
  methods: {
    downClient(){
      var that = this
      that.isShowTip = true
      setTimeout(function(){
        that.isShowTip = false
      }, 3000)
    }
  }
}
</script>
